import React from 'react';
import Imgbg from "../images/bg/bg6.jpeg"; // Image for the banner background

const Franchise = () => {
  return (
    <div>
      {/* Page Banner Section */}
      <section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${Imgbg})` }} // Dynamic background image
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Franchise </h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">Home</a>
                <span>/</span>Franchise
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            {/* <div className="lg:w-6/12 mt-8 lg:mt-0">
              <img
                src="images/about/contactusbanner.png"
                alt="Unikaa Beauty beauty Franchise"
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div> */}
          </div>
        </div>
      </section>

      {/* Franchise Content Section */}
      <section className="container mx-auto py-16">
        <div className="text-center mb-10">
          <h3 className="text-3xl font-semibold text-gray-800">Why Partner with Unikaa Beauty ?</h3>
          <p className="text-lg text-gray-600 mt-4">
            Join the thriving beauty industry with Unikaa Beauty . Our brand has built a reputation for exceptional
            services, customer satisfaction, and innovative beauty treatments. As a franchisee, you will be part of this legacy.
          </p>
        </div>

        {/* Franchise Benefits */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-800 mb-4">Comprehensive Training & Support</h4>
            <p className="text-gray-600">
              We provide extensive training in beauty services, beauty management, marketing, and customer service to ensure
              you are fully prepared for success. Our support team will guide you every step of the way to ensure smooth operations.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-800 mb-4">Established Brand & Customer Loyalty</h4>
            <p className="text-gray-600">
              Leverage the trust and loyalty that Unikaa Beauty  has built over the years. Our strong brand presence and
              customer base make it easier for franchisees to start strong and build a successful business.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-800 mb-4">Cutting-Edge Beauty Services</h4>
            <p className="text-gray-600">
              Stay ahead of the competition by offering the latest beauty treatments and services. From skincare to haircare and
              wellness, our menu is designed to meet the evolving needs of our customers.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-800 mb-4">Low Investment & High ROI</h4>
            <p className="text-gray-600">
              Our franchise model is designed to be cost-effective with a high return on investment. The beauty industry is booming,
              and with Unikaa, you can tap into this growing market while benefiting from our proven business model.
            </p>
          </div>
        </div>
      </section>

      {/* How to Get Started Section */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-semibold text-gray-800">How to Get Started with Your Unikaa Franchise</h3>
          <p className="text-lg text-gray-600 mt-4">
            Ready to become a Unikaa Beauty  franchisee? Follow these simple steps to kickstart your journey:
          </p>
          <div className="mt-8">
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800">Step 1: Contact Us</h4>
              <p className="text-gray-600 mt-2">Reach out to our team for more information about the franchise opportunity and to schedule an initial consultation.</p>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800">Step 2: Review Franchise Agreement</h4>
              <p className="text-gray-600 mt-2">We will provide you with the franchise agreement, which outlines the terms, support, and expectations from both parties.</p>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800">Step 3: Franchise Onboarding</h4>
              <p className="text-gray-600 mt-2">Once the agreement is signed, we’ll guide you through the onboarding process, from setting up your salon to marketing your services.</p>
            </div>
            <div className="mb-6">
              <h4 className="text-xl font-semibold text-gray-800">Step 4: Open Your Unikaa beauty</h4>
              <p className="text-gray-600 mt-2">After all preparations are made, it’s time to open your Unikaa Beauty  and begin offering our premium services to your community.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-[#6a7afe] py-16 text-white text-center">
        <h3 className="text-3xl font-semibold mb-4">Start Your Unikaa Beauty  Franchise Today!</h3>
        <p className="text-lg mb-8">Join a leading brand in the beauty industry and enjoy financial success while offering exceptional services to your clients.</p>
        <a
          href="/contactus"
          className="bg-white text-[#6a7afe] px-8 py-3 text-xl font-semibold rounded-full hover:bg-pink-200"
        >
          Get Started Now
        </a>
      </section>
    </div>
  );
}

export default Franchise;
